import { Text } from '@chakra-ui/react';
import StatMap from '../../Data/StatMap';


    function WidgetCard(props) {
     
     const { name, statistic, threshold, cardWidth, cardHeight, theme, value } = props;
     
     const cleanTitle = (unclean) => {
        let cleanSymbee;
        if(unclean.includes('_')){
         cleanSymbee = unclean;   
        } else {
            cleanSymbee = unclean[0] + unclean.slice(1).replace(/([A-Z])/g, '_$1');
        }
        const words = cleanSymbee.toLowerCase().split("_");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        let result =  words.join(" ");
        return result;
    };
    
    const cleanValue = (unclean, metric) => {
      if (StatMap[metric] === 'callbacktime') {
        if (unclean === 0) {
          return ' - ';
        } else {
          const date = new Date(unclean * 1000);
          const result = date.toLocaleTimeString("en-NZ", {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          });
          return result;
        }
      } else if (StatMap[metric] === 'percentage') {
        return `${unclean}%`;
      } else if (StatMap[metric] === 'time') {
        const hours = Math.floor(unclean / 3600);
        const minutes = Math.floor((unclean % 3600) / 60);
        const remainingSeconds = unclean % 60;
        let timeString;
        if (hours > 0) {
          timeString = `${hours}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        } else {
          timeString = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        }
        return timeString;
      } else {
        return unclean;
      }
    };


    const testThreshold = () => {
        if(threshold?.second?.operand && threshold?.second?.value) {
            if(threshold.second.operand === '>'){
                if(value > threshold.second.value) {
                    return 'second';
                }
            } else if(threshold.second.operand === '<') {
                if(value < threshold.second.value) {
                    return 'second';
                }
            }
        } 
        if(threshold?.first?.operand && threshold?.first?.value) {
            if(threshold.first.operand === '>'){
                if(value > threshold.first.value) {
                    return 'first';
                }
            } else if(threshold.first.operand === '<') {
                if(value < threshold.first.value) {
                    return 'first';
                }
            }
        }
        
        else return false;
    };
    
    const classes = {
        card: {
            borderRadius: '20px',
            boxShadow: '5px 5px 20px #b9b9b9, -5px -5px 20px #ffffff',
            color: testThreshold() === 'second' ? 'white' : testThreshold() === 'first' ? '#000' : theme.fontColor,
            background: testThreshold() === 'second' ? '#e11919' : testThreshold() === 'first' ? '#FFBF00' : theme.cardColor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: cardWidth,
            height: cardHeight,
            overflow: 'hidden'
        },
    };
     
     
     return (
         
         <div style={classes.card}>
            <Text fontSize={{ base: '8px', md: '12px', lg: '16px', xl: '20px', '2xl': '25px' }}>{name}</Text>
            <Text fontSize={{ base: '12px', md: '16px', lg: '23px', xl: '40px', '2xl': '70px' }} as='b'>{cleanValue(value, statistic)}</Text>
            <Text fontSize={{ base: '8px', md: '12px', lg: '16px', xl: '20px', '2xl': '25px' }} >{cleanTitle(statistic)}</Text>
         </div>
         
     );
    
}

export default WidgetCard;