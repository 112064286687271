import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

const AlertComponent = ({title, body}) => {



    return (

        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription>{body}</AlertDescription>
        </Alert>
    );
};

export default AlertComponent;