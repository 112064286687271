import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import theme from './theme';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
<React.StrictMode>
    <ChakraProvider theme={theme}>  
        <App />
    </ChakraProvider>
</React.StrictMode>
);