import { useState } from 'react';
import AlertComponent from './AlertComponent';
import { 
        Text, 
        Button,
        Input,
    } from '@chakra-ui/react';

function LoginPage(props) {
    const { signIn, authInProgress, aliasError } = props;
    const [alias, setAlias] = useState(''); 
     
     return (
         
         <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', padding: '2em', width: '100vw', height: '100vh', backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bgImage.png"})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
             <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', padding: '1em', width: '100%', height: '10%' }}>
                <img alt='logo' src={process.env.PUBLIC_URL + "/images/one-nz-logo-white.png"} style={{height: '100%', marginRight: '3em'}} />
                
             </div>
            {authInProgress ?
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', padding: '1em', width: '50%', height: '60%' }}>
                <Text color='#fff' fontSize='3xl'> Signing in...</Text>
             </div>
            
            :
             <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'space-evenly', padding: '1em', width: '50%', height: '60%' }}>
                <Text color='#fff' fontSize='5xl' as='b'> Cornerstone CX Wallboard</Text>
                <Input placeholder='Enter Company ID...' value={alias} onChange={(e) => setAlias(e.target.value) } backgroundColor='#000' color='#fff' focusBorderColor='#23D7A3' size='lg' />
                
                <Button variant='solid' colorScheme='whiteAlpha' onClick={() => signIn(alias)}>Proceed to Sign-In</Button>
                {aliasError ? 
                    <AlertComponent title='Invalid Company ID:' body='We were unable to sign you in' />

                : null }
             </div>
            }
         </div>
         
     );
    
}

export default LoginPage;